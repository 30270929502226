<template>
    <div class="morePrivilege">
        <div class="navbar">
            <div class="navbar_btn" @click="$router.go(-1)">
                <svg-icon iconClass="leftArrow"></svg-icon>
            </div>
            <div class="navbar_title">权益对比</div>
            <div class="rightBtn"></div>
        </div>
        <ImgDecypt :src="rightsCompareImg" class="privilegeImg" :key="rightsCompareImg" />s
    </div>
</template>

<script>
    import { queryVipRights } from '@/api/mine/vip.js'
    export default {
        data() {
            return {
                rightsCompareImg: '',
            }
        },
        created() {
            this.queryVipRights();
        },
        methods: {
            async queryVipRights() {
                let ret = await this.$Api(queryVipRights);
                if (ret && ret.code == 200) {
                    this.rightsCompareImg = ret.data.rightsCompareImg;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .morePrivilege {
        // background: $whiteThree;
        height: 100vh;
        background: #000;

        .navbar {
            // position: fixed;
            // top: 0;
            background: $vermillion;
            width: 100%;
            color: $white1;
            height: 44px;
            font-size: 20px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .navbar_title {
                font-weight: 600;
            }

            .rightBtn {
                width: 25px;
            }
        }

        .privilegeImg {
            width: 100%;
            height: 80%;
            margin-top: 30px;
        }


    }
</style>